import React, { useEffect } from 'react';
import '../Home.css';
import { Box, Button, Center, Checkbox, Divider, Flex, Progress, Spacer, Text, Tooltip, VStack, useToast } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCheck, faCheckToSlot, faHand, faMessage } from '@fortawesome/free-solid-svg-icons';
import { IPoll, PollType } from '../types';
import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';
import { getPollResults, getUserVote, stopPoll, votePoll } from '../API/poll';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { useUser } from '../UserContext';

export function SortableItem(props:any) {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({id: props.id});
    
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
    
    return (
      <Box cursor={'grab'} bgColor={"white"} border={"1pt solid #e2e2dd"} p={2} ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <Flex>
        {props.id}
        <Spacer />
        <FontAwesomeIcon icon={faBars} style={{marginTop:"5px"}} />
        </Flex>
      </Box>
    );
  }
  
export function Poll(poll:IPoll) {
    const [selectedOption, setSelectedOption] = React.useState<any>([]);
    const [pollEnded, setPollEnded] = React.useState<boolean>(false);
    const [hasVoted, setHasVoted] = React.useState<boolean>(false);
    const [results, setResults] = React.useState<any>(null); // {option: count}
    const toast = useToast();
    const { user } = useUser();

    const getResults = () => {
      getPollResults(poll._id).then((response) => {
        if (response.status === 200) {
          setResults(response.data.results);
        }
      });
    }
  
    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );
  
    const handleOptionSelect = (option: string) => {
      if (poll.type === PollType.SingleChoice) {
        // If it's the current selected option, unselect it
        if (selectedOption === option) {
          setSelectedOption('');
          return;
        }
        setSelectedOption(option);
      } else if (poll.type === PollType.MultiChoice) {
        if (selectedOption.includes(option)) {
          setSelectedOption(selectedOption.filter((o:any) => o !== option));
        } else {
          setSelectedOption([...selectedOption, option]);
        }
      } else if (poll.type === PollType.Sort) {
        // setSelectedOption(arrayMove(selectedOption, selectedOption.indexOf(option), 0));
      }
    };

    const totalRemainingTime = () => {
        // If remaining time is negative, the poll has ended
        if (differenceInMinutes(new Date(poll.deadline), new Date()) < 0) {
            return "Terminé";
        }
        // In days, hours, minutes
        const days = differenceInDays(poll.deadline, new Date());
        const hours = differenceInHours(poll.deadline, new Date()) - days * 24;
        const minutes = differenceInMinutes(poll.deadline, new Date()) - (days * 24 + hours) * 60;
        return `${days} jours, ${hours} heures, ${minutes} minutes`;
    }
  
    useEffect(() => {
      if (new Date() > new Date(poll.deadline)) {
        setPollEnded(true);
        getResults();
      }
    }, []);
  
    // Calculate percentage of the progress bar, which is the time ellapsed since the poll was created (i.e., ellapsed between poll.createdAt and the deadline)
    const progressPercentage = () => {
      const total = differenceInHours(poll.deadline, poll.createdAt || new Date());
      const ellapsed = differenceInHours(new Date(), poll.createdAt || new Date());
      return Math.round((ellapsed / total) * 100);
    }
  
    const vote = () => {
      setHasVoted(true);
      votePoll(poll._id, selectedOption);
      toast({
        title: "Vote enregistré",
        description: "Merci ! Votre vote a bien été enregistré.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  
    // Check if user has already voted for this poll
    useEffect(() => {
      getUserVote(poll._id).then((response) => {
        if (response.status === 200) {
          setSelectedOption(response.data.pollVote.option);
          setHasVoted(true);
        } else {
          // Set default list if Sort poll
          if (poll.type === PollType.Sort) {
            setSelectedOption(poll.options);
          }
        }
      }).catch((error) => {
        if (poll.type === PollType.Sort) {
          setSelectedOption(poll.options);
        }
      });
    }, []);

    const stop_poll = () => {
      stopPoll(poll._id).then((response) => {
        setPollEnded(true);
        getResults();
        toast({
          title: "Sondage clôturé",
          description: "Le sondage a été clôturé en avance.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }).catch((error) => {
        toast({
          title: "Erreur",
          description: "Impossible de clôturer le sondage : " + error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    }
  
    return (
      <Box boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} p={4} border={"1pt solid #e2e2dd"} bgColor={"white"} width={"100%"} position={'relative'}>
        {!hasVoted && !pollEnded && (<>
            <Box zIndex={2} position={'absolute'} top={'-15px'} right={"-15px"} cursor={'default'} border={"1pt solid #e2e2dd"} bgColor={'red.600'} p={3} color={"white"} fontWeight={700} width={"fit-content"}>
                <FontAwesomeIcon icon={faMessage} /> Vous n'avez pas encore voté à ce sondage
            </Box>
        </>)}
        <Text fontWeight={'800'} letterSpacing={"-.01rem"} fontSize={'xl'} color={"#4a4a4a"}>{poll.title}</Text>
        <Box p={2}>
          <Text fontWeight={'400'} fontSize={'sm'} color={"gray"} align={"justify"}>{poll.description}</Text>
  
          <Divider mt={2} mb={2} />

          {pollEnded &&
            <Box width={"100%"} height={"300px"} position={"relative"} right={"20px"} mb={10}>
              <Text fontWeight={700} p={3}>Résultats du sondage :</Text>
              <ResponsiveContainer width="100%" height="100%" 
                onResize={
                  () => {
                    // setChartRendered(true)
                  }
                }>
                    <BarChart data={
                        results ? Object.keys(results).map((option) => ({option, count: results[option]})) : []
                    }>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="option" />
                        <YAxis dataKey="count"/>
                        <Bar dataKey="count" fill="#006977" />
                    </BarChart>
              </ResponsiveContainer>
            </Box>
          }
  
          {poll.type == PollType.SingleChoice && (<>
            <Text fontWeight={'500'} mb={4}>{pollEnded ? "Votre réponse :" : "Sélectionnez votre réponse :"}</Text>
  
            <VStack spacing={0} align={'start'} ms={2}>
              {poll.options.map((option, index) => (
                <Button
                    minWidth={'100px'}
                    rounded={0}
                    key={index}
                    variant={selectedOption === option ? 'solid' : 'outline'}
                    colorScheme={selectedOption === option ? 'green' : 'gray'}
                    mt={1}
                    isDisabled={pollEnded || hasVoted}
                    onClick={() => handleOptionSelect(option)}
                >
                  {option}
                </Button>
              ))}
            </VStack>
          </>)}
  
          {poll.type == PollType.MultiChoice && (<>
            <Text fontWeight={'500'} mb={4}>{pollEnded ? "Votre réponse :" : "Sélectionnez une réponse ou plus :"}</Text>
  
            <VStack spacing={0} align={'start'}>
              {poll.options.map((option, index) => (
                <Checkbox
                  key={index}
                  isChecked={selectedOption.includes(option)}
                  colorScheme={selectedOption.includes(option) ? 'green' : 'gray'}
                  isDisabled={pollEnded || hasVoted}
                  onChange={() => handleOptionSelect(option)}
                  mb={2}
                  ms={2}
                  size={'lg'}
                  fontSize={'md'}
                  fontWeight={'400'}
                >
                {option}
                </Checkbox>
              ))}
            </VStack>
          </>)}
  
          {poll.type === PollType.Sort && (
            <Box>
              <Text fontWeight={'500'} mb={4}>{pollEnded ? "Votre réponse :" : "Glissez et déposez ces réponses par ordre de préférence :"}</Text>
              
              <Box ps={2} pt={2} opacity={(pollEnded || hasVoted) ? 0.7 : 1}>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={(e:any) => {
                    const {active, over} = e;
                    if (active.id !== over.id) {
                      setSelectedOption(arrayMove(selectedOption, selectedOption.indexOf(active.id), selectedOption.indexOf(over.id)));
                    }
                  }}
                >
                  <SortableContext
                    disabled={pollEnded || hasVoted}
                    items = {selectedOption}
                    strategy={verticalListSortingStrategy}
                  >
                    {selectedOption.map((option:any, index:number) => (
                      <SortableItem key={index} id={option} />
                    ))}
                  </SortableContext>
                </DndContext>
              </Box>
            </Box>
          )}
        </Box>
  
        {selectedOption != ''&& !hasVoted && (
          <Flex>
            <Spacer />
            <Tooltip hasArrow p={2} placement='top' label="Vous ne pourrez plus changer votre vote après avoir confirmé">
              <Button onClick={vote} variant={'outline'} colorScheme={'green'} mt={4} rightIcon={<FontAwesomeIcon icon={faCheck} />} rounded={0}>Confirmer mon vote</Button>
            </Tooltip>
          </Flex>
        )}
  
        {hasVoted && (
            <Flex>
                <Spacer />
                <Box bgColor={'green.500'} p={3} mt={4} ms={2} mb={2}>
                    <Text fontWeight={700} color={"white"} cursor={'default'}>
                        <FontAwesomeIcon icon={faCheckToSlot} style={{marginRight:"5px"}} /> Vous avez déjà voté pour ce sondage
                    </Text>
                </Box>
            </Flex>
        )}
  
        <Divider mt={2} mb={2} />
  
        {!pollEnded && (<>
          <Text 
            fontWeight={700} 
            letterSpacing={'-.05rem'} 
            color={
              differenceInMinutes(poll.deadline, new Date()) < 0 ? "gray.500" :
              (differenceInHours(poll.deadline, new Date()) < 24 ? "red.500" : "green.500")
            }>
            Temps restant au sondage : {totalRemainingTime()}
            </Text>
        </>)}
        {pollEnded && (
          <Text>
            Ce sondage est terminé.
          </Text>
        )}

        {(user?.matricule == poll?.author || user?.rank == 'admin') && !pollEnded &&
          <Button variant={'outline'} colorScheme={'red'} size={"sm"} mt={4} rightIcon={<FontAwesomeIcon icon={faHand} />} onClick={stop_poll} rounded={0} borderWidth={'1pt'} >Clôturer ce sondage en avance</Button>
        }
  
        <Flex mt={3}>
          <Spacer />
          <Text fontSize={'sm'} color={"#adb5bd"}>{poll.votes_count} {(poll.votes_count && poll.votes_count == 1) ? "vote" : "votes"} <FontAwesomeIcon icon={faCheckToSlot} style={{marginLeft:"5px"}} /></Text>
        </Flex>
      </Box>
    );
  }
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './Home';
import "hover.css";
import Forum from './Forum/Forum';
import News from './News/News';
import NewsEditor from './News/NewsEditor';
import Article from './News/Article';
import { UserProvider, useUser } from './UserContext';
import Dossiers from './Dossiers/Dossiers';
import { registerLicense } from '@syncfusion/ej2-base';
import Archives from './Dossiers/Archives';
import Elections from './elections/Elections';
import Bureau from './bureau/Bureau';
import Account from './account/Account';
import Cookies from 'js-cookie';
import { Login } from './Login';
import ElectionsEnd from './elections/ElectionsEnd';
import { Whitelist } from './Whitelist';
import { Search } from './Search';
import { Welcome } from './Welcome';
import { Contact } from './Contact';

if (process.env.REACT_APP_SYNCFUSION_LICENSE_KEY){
  registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);
}
else
  console.error("No Syncfusion license key found. Please set the REACT_APP_SYNCFUSION_LICENSE_KEY environment variable.");

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/account',
    element: <Account />,
  },
  {
    path: '/welcome',
    element: <Welcome />,
  },
  {
    path: '/forum',
    element: <Forum />,
  },
  {
    path: '/forum/:threadId',
    element: <Forum />,
  },
  {
    path: '/dossiers',
    element: <Dossiers />,
  },
  {
    path: '/dossiers/:slug',
    element: <Dossiers />,
  },
  {
    path: '/elections',
    element: <Elections />,
  },
  {
    path: '/elections/end',
    element: <ElectionsEnd />,
  },
  {
    path: '/bureau',
    element: <Bureau />,
  },
  {
    path: '/archives',
    element: <Archives />,
  },
  {
    path: '/archives/:slug',
    element: <Archives />,
  },
  {
    path: '/news',
    element: <News />,
  },
  {
    path: '/news/edit',
    element: <NewsEditor />,
  },
  {
    path: '/news/:slug',
    element: <Article />,
  },
  {
    path: '/search/:tag',
    element: <Search />,
  },
  {
    path: '/contact',
    element: <Contact />,
  },
  {
    path: '*',
    element: <Home />,
  },
]);

const theme = extendTheme({
  fonts: {
    body: `'Source Sans 3', sans-serif`,
    heading: `'Source Sans 3', sans-serif`,
  },
});

const App = () => {
  const { user, setUser } = useUser();
  const [tokenError, setTokenError] = useState(true);

  useEffect(() => {
      const matToken = Cookies.get('jwt_adere');
      if (matToken) {
        setTokenError(false);
      }
      else {
        setTokenError(true);
      }
      
      const tokenErrorListener = (e: any) => {
        console.log("TOKEN_ERROR received");
        setTokenError(true);
      }
      document.addEventListener("TOKEN_ERROR", tokenErrorListener);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      {tokenError ? (
        <Login />
      ) : ( user?.whitelist ? (
        <RouterProvider router={router} />
        ) : (<Whitelist />)
      )}
    </ChakraProvider>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <UserProvider initialUser={{rank: "user", firstname:"", lastname:"", matricule:"", whitelist:false}}>
    <App />
  </UserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

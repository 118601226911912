import React, { useEffect, useState } from 'react';
import '../Home.css';
import { Box, Button, Center, Checkbox, Divider, Flex, FormControl, FormHelperText, FormLabel, Grid, Input, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Skeleton, SkeletonText, Spacer, Text, Tooltip, UnorderedList, useDisclosure, useToast, VStack } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faEye, faFile, faPaperclip, faPenToSquare, faSquarePollHorizontal, faTags, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import Threads from '../Forum/Threads';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { addDossierFiles, closeDossier, deleteDossier, getDossier, getPolls, removeDossierFile, updateDossier, updateDossierDeadline, watchDossier } from '../API/dossier';
import { IDossier, IPoll } from '../types';
import { differenceInDays, differenceInHours, differenceInMinutes, formatDate } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useUser } from '../UserContext';
import { Poll } from '../Poll/Poll';
import { NewPoll } from '../Poll/NewPoll';
import { downloadFile, uploadFiles } from '../API/file';
import TextEditor from '../components/TextEditor';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';


interface ChangeDeadlineModalProps {
  isOpen:boolean;
  onOpen:any;
  onClose:any;
  callback:Function;
  currentDeadline:Date|null;
}
const ChangeDeadlineModal: React.FunctionComponent<ChangeDeadlineModalProps> = ({isOpen, onOpen, onClose, callback, currentDeadline}) => {

  // Initialize deadline state to tomorrow midnight
  const [deadline, setDeadline] = useState<Date|null>(new Date(new Date().setHours(23, 59, 59, 0)));

  useEffect(() => {
    if (currentDeadline) {
      setDeadline(currentDeadline);
    }
  }, [isOpen]);

  const setDeadlineHandler = (e:any) => {
    if (e.target.checked) {
      setDeadline(null);
    } else {
      setDeadline(new Date());
    }
  }

  const update_deadline = (new_deadline:any) => {
    setDeadline(new_deadline.value);
  }

  return <Modal 
    onClose={onClose} 
    size={"xl"} 
    isOpen={isOpen}
    motionPreset='scale'
    closeOnOverlayClick={true}
    >
      <ModalOverlay backdropFilter='auto'
      backdropInvert='10%'
      backdropBlur='2px' />
      <ModalContent>
        <ModalHeader>
          <Text p={3} ps={0} fontWeight={'900'} lineHeight={"1"} letterSpacing={"-.1rem"} color={"#314e89"} fontSize={'3xl'} mb={0}>Modifier la deadline</Text>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody mt={0} pt={0}>
          <UnorderedList ps={2}>
            <ListItem>Si vous entrez une deadline dans le passé, le dossier sera clôturé immédiatement.</ListItem>
            <ListItem>Si vous prolongez la deadline et que le dossier est clôturé, il sera réouvert.</ListItem>
            <ListItem>Vous pouvez également choisir de ne mettre aucune deadline et de le clôturer manuellement.</ListItem>
          </UnorderedList>

          <FormControl mt={3}>
            <FormLabel fontWeight={600}>Nouvelle Deadline</FormLabel>
            <VStack ms={2} align={'start'} spacing={2}>
              <Checkbox
                colorScheme='orange'
                onChange={setDeadlineHandler}
                isChecked={deadline === null}
              >
                Pas de deadline
              </Checkbox>
              {deadline && 
                <DateTimePickerComponent 
                  locale='fr' 
                  width={'100%'} 
                  id="datetimepicker"
                  value={deadline || new Date()}
                  allowEdit={false}
                  onChange={(e:any) => {update_deadline(e);}}
                />
              }
            </VStack>
            
            <FormHelperText ms={2}>Si vous n'indiquez pas de deadline, vous devrez clôturer le dossier manuellement.</FormHelperText>
          </FormControl>

          <Divider mt={5} mb={3} />

          <Flex>
            <Spacer />
            <Button 
                  p={6} m={3} mt={3} color={"white"} _hover={{backgroundColor:"#F09865"}} bgColor={"#F07F3D"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} 
                  onClick={() => {callback(deadline); onClose()}} >
                  APPLIQUER
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
  </Modal>
}

function DeleteDossierModal(isOpen:boolean, onOpen:any, onClose:any, callback:Function) {
  return <Modal 
    onClose={onClose} 
    size={"xl"} 
    isOpen={isOpen}
    motionPreset='scale'
    closeOnOverlayClick={true}
    >
      <ModalOverlay backdropFilter='auto'
      backdropInvert='10%'
      backdropBlur='2px' />
      <ModalContent>
        <ModalHeader>
          <Text fontWeight={'900'} lineHeight={"1"} letterSpacing={"-.1rem"} color={"#314e89"} fontSize={'3xl'} mb={5}>Supprimer le dossier</Text>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody mt={0} pt={0}>
          <Center>
            <Text>Attention, la suppression d'un dossier est irréversible. Si vous supprimez ce dossier, il n'apparaitra plus nulle part, et les sondages et forums qui y sont liés seront également supprimés.</Text>
          </Center>

          <Flex>
            <Spacer />
            <Button
                p={6} m={3} mt={5} color={"white"} _hover={{backgroundColor:"#d92a2e"}} bgColor={"#b32326"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={() => {callback(); onClose()}} >
                CONFIRMER LA SUPPRESSION
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
  </Modal>
}

function CloseDossierModal(isOpen:boolean, onOpen:any, onClose:any, callback:Function) {
  return <Modal 
    onClose={onClose} 
    size={"xl"} 
    isOpen={isOpen}
    motionPreset='scale'
    closeOnOverlayClick={true}
    >
      <ModalOverlay backdropFilter='auto'
      backdropInvert='10%'
      backdropBlur='2px' />
      <ModalContent>
        <ModalHeader>
          <Text fontWeight={'900'} lineHeight={"1"} letterSpacing={"-.1rem"} color={"#314e89"} fontSize={'3xl'} mb={5}>Fermer le dossier</Text>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody mt={0} pt={0}>
          <Center>
            <Text>Si vous clôturez ce dossier, il sera archivé et les sondages et forums qui y sont liés ne pourront plus être modifiés.</Text>
          </Center>

          <Flex>
            <Spacer />
            <Button
                p={6} m={3} mt={5} color={"white"} _hover={{backgroundColor:"#d92a2e"}} bgColor={"#b32326"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={() => {callback(); onClose()}} >
                CONFIRMER LA CLÔTURE
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
  </Modal>
}

export function Dossier(isOpen:boolean, onOpen:any, onClose:any, dossier_id:string) {
    const ALLOWED_FORMATS = ["image/jpeg", "image/png", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "text/plain", "text/csv", "application/rtf", "text/html", "application/zip", "audio/mpeg", "audio/x-ms-wma", "audio/ogg", "audio/wav", "video/mp4", "video/quicktime", "video/x-ms-wmv", "video/avi", "video/mpeg", "video/3gpp", "video/3gpp2", "application/zip", "application/x-rar-compressed", "application/x-tar", "application/x-gzip", "application/x-7z-compressed", "application/x-zip-compressed"];
    const [filesUploading, setFilesUploading] = React.useState<boolean>(false);
    const MAX_FILES_NB = 5;
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (filesUploading)
        return;
      if (e.target.files) {
        const new_files = Array.from(e.target.files);
        if (new_files.length > MAX_FILES_NB) {
          toast({
            title: "Erreur",
            description: "Vous ne pouvez pas ajouter plus de " + MAX_FILES_NB + " fichiers à la fois.",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
            size: "md",
          });
          return;
        }
        setFilesUploading(true);

        // Upload files and get the IDs, then update the draft with the IDs
        const formData = new FormData();
        new_files.forEach((file, index) => {
          formData.append('files', file);
        });
        uploadFiles(formData).then((resp) => {
          if (resp.status === 201) {
            const ids = resp.data.ids;

            // Update in DB
            addDossierFiles(dossier_id, ids).then((response) => {
              const new_dossier:IDossier = response.data.dossier;
              setDossier(new_dossier);
              toast({
                title: "Fichiers envoyés",
                description: "Les fichiers ont bien été envoyés.",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top-right",
                size: "md",
              });
              setFilesUploading(false);
            }).catch((error) => {
              toast({
                title: "Erreur",
                description: "Une erreur est survenue lors de l'envoi des fichiers : "+error.response.data.message,
                status: "error",
                duration: 8000,
                isClosable: true,
                position: "top-right",
                size: "md",
              });
              setFilesUploading(false);
            });
          }
        }).catch((error) => {
          toast({
            title: "Erreur",
            description: "Une erreur est survenue lors de l'envoi des fichiers : "+error.response.data.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-right",
            size: "md",
          });
          setFilesUploading(false);
        });
      }
    }

    const delete_file = (file_id:string) => {
      if (filesUploading)
        return;

      removeDossierFile(dossier_id, file_id).then((response) => {
        const new_dossier:IDossier = response.data.dossier;
        setDossier(new_dossier);
        toast({
          title: "Fichier supprimé",
          description: "Le fichier a bien été supprimé.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
          size: "md",
        });
      } ).catch((error) => {
        toast({
          title: "Erreur",
          description: "Impossible de supprimer ce fichier : "+error.response.data.message,
          status: "error",
          duration: 8000,
          isClosable: true,
          position: "top-right",
          size: "md",
        });
      });
    }

    const newpoll_disclosure = useDisclosure();
    const close_disclosure = useDisclosure();
    const [dossier, setDossier] = React.useState<IDossier|null>(null);
    const [deadlineText, setDeadlineText] = React.useState<string>('');
    const [deadlinePassed, setDeadlinePassed] = React.useState<boolean>(false);
    const [polls, setPolls] = React.useState<IPoll[]>([]);
    const { user } = useUser();
    const toast = useToast();

    // Skeletons
    const [dossierLoading, setDossierLoading] = React.useState<boolean>(true);
    const [pollsLoading, setPollsLoading] = React.useState<boolean>(true);

    useEffect(() => {
      if (!isOpen) return;
      getDossier(dossier_id).then((response) => {
        if (response.status === 200) {
          setDossier(response.data.dossier);
          const resp_dossier:IDossier = response.data.dossier;

          // Set deadline text
          manageDeadline(resp_dossier);

          // Update editing refs
          titleRef.current?.setAttribute('value', resp_dossier.title);
          setEditedContent(resp_dossier.content);
          setDossierLoading(false);
        }
      });
    }, [isOpen]);

    const manageDeadline = (dossier:IDossier) => {
      if (!dossier.deadline) {
        setDeadlineText('Pas de deadline');
      } else {
        if (new Date(dossier.deadline) < new Date()) {
          setDeadlinePassed(true);
          setDeadlineText(`Clôturé le : ${formatDate(dossier.deadline, "dd MMMM yyyy", {locale:fr})}`);
        } else {
          setDeadlinePassed(false);
          if (differenceInDays(dossier.deadline, new Date()) > 0) {
            const plural = differenceInDays(dossier.deadline, new Date()) > 1 ? 's' : '';
            setDeadlineText(`Deadline: ${formatDate(dossier.deadline, "dd MMMM yyyy", {locale:fr})} - Dans ${differenceInDays(dossier.deadline, new Date())} jour`+plural);
          } 
          else if (differenceInHours(dossier.deadline, new Date()) > 0 && differenceInDays(dossier.deadline, new Date()) == 0) {
            const plural = differenceInHours(dossier.deadline, new Date()) > 1 ? 's' : '';
            setDeadlineText(`Deadline: ${formatDate(dossier.deadline, "dd MMMM yyyy", {locale:fr})} - Dans ${differenceInHours(dossier.deadline, new Date())} heure`+plural);
          } 
          else if (differenceInHours(dossier.deadline, new Date()) == 0) {
            const plural = differenceInMinutes(dossier.deadline, new Date()) > 1 ? 's' : '';
            setDeadlineText(`Deadline: ${formatDate(dossier.deadline, "dd MMMM yyyy", {locale:fr})} - Dans ${differenceInMinutes(dossier.deadline, new Date())} minute`+plural);
          }
        }
      }
    }

    
    useEffect(() => {
      if (!isOpen || !dossier) return;
      if (!dossier.user_seen)
        watchDossier(dossier_id);
    }, [isOpen, dossier]);

    
    useEffect(() => {
      if (!isOpen) return;
      getPolls(dossier_id).then((response) => {
        if (response.status === 200) {
          setPolls(response.data.polls);
          setPollsLoading(false);
        }
      });
    }, [isOpen]);

    const download_file = (file_id:string) => {
      downloadFile(file_id).then((resp) => {
        const contentDisposition = resp.headers['content-disposition'];
        const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      });
    }

    const close_dossier = () => {
      closeDossier(dossier_id).then((response) => {
        toast({
          title: "Dossier clôturé",
          description: "Ce dossier a été clôturé avec succès.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      }).catch((err) => {
        toast({
          title: "Erreur",
          description: "Impossible de clôturer ce dossier. Rechargez la page et réessayez.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    }

    const update_deadline = (new_deadline:Date|null) => {
      updateDossierDeadline(dossier_id, new_deadline).then((response) => {
        const new_dossier:IDossier = response.data.dossier;
        setDossier(new_dossier);
        manageDeadline(new_dossier);
        toast({
          title: "Deadline modifiée",
          description: "La deadline a bien été modifiée.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
          size: "md",
        });
      } ).catch((error) => {
        toast({
          title: "Erreur",
          description: "Impossible de modifier la deadline : "+error.response.data.message,
          status: "error",
          duration: 8000,
          isClosable: true,
          position: "top-right",
          size: "md",
        });
      });
    }

    const close_newpoll_handler = () => {
      newpoll_disclosure.onClose();
      setPollsLoading(true);
      getPolls(dossier_id).then((response) => {
        if (response.status === 200) {
          setPolls(response.data.polls);
        }
      }).finally(() => {
        setPollsLoading(false);
      });
    }

    const delete_disclosure = useDisclosure();
    const deadline_disclosure = useDisclosure();
    const delete_dossier = () => {
      deleteDossier(dossier_id).then((response) => {
        toast({
          title: "Dossier supprimé",
          description: "Ce dossier a été supprimé avec succès.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      });
    }

    // Title editing
    const [editingTitle, setEditingTitle] = useState<boolean>(false);
    const titleRef = React.useRef<HTMLInputElement>(null);
    const toggle_edit_title = () => {
      setEditingTitle(true);
    }
    const update_title = () => {
      const new_title = titleRef.current?.value;
      if (new_title) {
        setEditingTitle(false);
        let new_dossier:IDossier = {...dossier, title: new_title} as IDossier;
        setDossier(new_dossier);
        updateDossier(new_dossier);

        // Trigger new updateDossier event on document with the
        // new title and the dossier ID
        const event = new CustomEvent('updateDossier', {detail: {title: new_title, id: dossier_id}});
        document.dispatchEvent(event);
      }
    }

    // Content editing
    const [editingContent, setEditingContent] = useState<boolean>(false);
    const [editedContent, setEditedContent] = useState<string>('');
    const setEditedDossierContent = (content:string) => {
      let new_dossier:IDossier = {...dossier, content: content} as IDossier;
      setDossier(new_dossier);
      setEditedContent(content);
    }

    const saveEditedContent = () => {
      setEditingContent(false);
      let new_dossier:IDossier = {...dossier, content: editedContent} as IDossier;
      updateDossier(new_dossier);
    }

    return (<>
      <Modal 
        onClose={onClose} 
        size={"6xl"} 
        isOpen={isOpen}
        motionPreset='scale'
        closeOnOverlayClick={false}
        >
          <ModalOverlay backdropFilter='blur(5px)' />
          <ModalContent>
            <ModalHeader pt={"40px"}>
              {!editingTitle && !dossierLoading &&
                <Flex>
                  <Text cursor={'default'} fontWeight={'800'} lineHeight={"1"} letterSpacing={"-.1rem"} color={"#314e89"} fontSize={'3xl'} mb={5}>{dossier?.title}</Text>

                  {(user?.matricule == dossier?.author || user?.rank == 'admin') &&
                    <Button onClick={toggle_edit_title} variant={'link'} colorScheme={'blue'} size={'sm'}><FontAwesomeIcon icon={faPenToSquare} /></Button>
                  }
                </Flex>
              }
              {editingTitle &&
                <Flex>
                  <FormLabel width={"90%"}>
                    <Input ref={titleRef} defaultValue={dossier?.title} onKeyDown={(
                      event: React.KeyboardEvent<HTMLInputElement>
                    ) => {
                      if (event.key === 'Enter') {
                        update_title();
                      }
                    }} />
                  </FormLabel>
                  <Button width={"10%"} onClick={update_title}>OK</Button>
                </Flex>
              }
              {dossierLoading &&
                <Skeleton mb={5} height={"30px"} isLoaded={!dossierLoading} width={"500px"} />
              }
              {dossier?.bureau && (
                <Flex>
                  <Tooltip label="Ce dossier a été créé par le Bureau ADERE" p={3} hasArrow placement='right'>
                    <Text cursor={'default'} fontWeight={'700'} letterSpacing={"-.05rem"} color={"#a8589e"} fontSize={'xl'}><FontAwesomeIcon icon={faBriefcase} style={{marginRight:"5px"}} />Dossier Bureau ADERE</Text>
                  </Tooltip>
                  <Spacer />
                  <Text cursor={'default'} fontSize={'md'} color={"gray.800"}><FontAwesomeIcon icon={faCalendar} style={{marginRight:"5px"}} />{deadlineText}</Text>
                </Flex>
              )}
              {!dossier?.bureau && (
                <Flex>
                  <Text cursor={'default'} fontSize={'md'} color={"gray.800"}><FontAwesomeIcon icon={faCalendar} style={{marginRight:"5px"}} />{deadlineText}</Text>
                </Flex>
              )}
              {/* <Tooltip label="Mots-clés" p={3} hasArrow placement='right'>
                  <Box ms={0} fontSize={"sm"}>
                    <FontAwesomeIcon icon={faTags} style={{marginRight:"5px"}} />{dossier?.tags?.join(', ')}
                  </Box>
              </Tooltip> */}
              <Divider mt={3} />
            </ModalHeader>
  
            <ModalCloseButton />
  
            <ModalBody mt={0} pt={0}>
              <Text cursor={'default'} mb={3} ms={3} color={"#00707f"} fontWeight={'800'} fontSize={'sm'}>DESCRIPTION DU DOSSIER</Text>
              <Box mb={0} height={'4px'} bgColor={"#00707f"} width={'100%'} />
              <Box>
                <Box p={6} color={'#4a4a4a'} fontWeight={'400'} fontSize={'md'} textAlign={'justify'} mb={2}>
                  <SkeletonText isLoaded={!dossierLoading} noOfLines={8} spacing='4' skeletonHeight='2' />
                  {!editingContent && !dossierLoading &&
                    <Box className='dossier-content' dangerouslySetInnerHTML={{__html: dossier?.content ? dossier?.content : ''}} />
                  }

                  {editingContent &&
                  <Box>
                    <TextEditor content={editedContent} update_callback={setEditedDossierContent} disabled={false} maxLength={10000} />
                    <Flex>
                      <Spacer />
                      <Button onClick={saveEditedContent}>Enregistrer les modifications</Button>
                    </Flex>
                  </Box>
                  }

                  <Flex pe={4} mt={5}>
                    {!dossierLoading && !editingContent && (user?.matricule == dossier?.author || user?.rank == 'admin') &&
                      <Flex>
                        <Spacer />
                        <Tooltip label="Uniquement l'auteur du dossier et le Bureau peuvent voir ce bouton" p={3} hasArrow placement='top'>
                          <Button
                          ms={2} mt={0}
                          p={5} me={3} color={"white"} _hover={{backgroundColor:"#3e63af"}} bgColor={"#314e89"} size="sm" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                          onClick={() => {setEditingContent(true)}}
                          leftIcon={<FontAwesomeIcon icon={faPenToSquare} />} >
                            ÉDITER LE CONTENU
                          </Button>
                        </Tooltip>
                      </Flex>
                    }
                    <Spacer />
                    <Skeleton height={"20px"} isLoaded={!dossierLoading} width={"250px"} />
                    {!dossierLoading && 
                    <Box alignContent={"right"} alignItems={"right"} textAlign={"right"}>
                      <Text cursor={'default'} fontWeight={'400'} fontSize={'sm'} color={"#adb5bd"}>
                        -- {dossier?.author_name ? dossier?.author_name : "Anonyme"}, publié le {dossier && formatDate(dossier.createdAt, "dd MMMM yyyy à HH:mm", {locale:fr})}
                      </Text>
                      <Text cursor={'default'} fontWeight={'400'} fontSize={'sm'} color={"#adb5bd"}>Vu par {dossier?.views_count} personne{(dossier?.views_count || 0) > 1 ? 's':''} <FontAwesomeIcon icon={faEye} style={{marginLeft:"5px", position:"relative", top:"2px"}} /></Text>
                    </Box>
                    }
                  </Flex>
                  
                  <Divider mb={5} mt={5} />

                  {dossierLoading &&
                    <Box mt={5}>
                      <Text fontSize={'lg'} fontWeight={'700'} color={"#314e89"} mb={2}>
                      <FontAwesomeIcon icon={faPaperclip} style={{marginRight:"5px"}} />Fichiers joints
                      </Text>
                      <Grid templateColumns='repeat(4, 2fr)' gap={6} >
                          {[1, 2, 3].map((_, index) => (
                            <Skeleton key={index} height={"30px"} isLoaded={!dossierLoading} />
                          ))}
                      </Grid>
                    </Box>
                  }
                  {!dossierLoading && dossier?.attached_files && dossier?.attached_files.length > 0 && (
                    <Box mt={5}>
                      <Text fontSize={'lg'} fontWeight={'700'} color={"#314e89"} mb={2}>
                      <FontAwesomeIcon icon={faPaperclip} style={{marginRight:"5px"}} />Fichiers joints
                      </Text>

                      <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(4, 1fr)']} gap={2}>
                        {dossier?.attached_files.map((attachment, index) => (
                          <Box position={"relative"} key={index} cursor={'pointer'} bgColor={'white'} p={3} textAlign={'center'} border={"1pt solid #e2e2dd"} onClick={() => {download_file(attachment.id)}}>
                            <Text fontWeight={'400'} ><FontAwesomeIcon icon={faFile} style={{marginRight:"5px"}} />
                            {/* If the name is longer than 15 characters, cut it and add (...) before the extension */}
                            {attachment.name.length > 15 ? `${attachment.name.slice(0, 15)}...${attachment.name.split('.').pop()}` : attachment.name}
                            </Text>

                            {(user?.matricule == dossier?.author || user?.rank == 'admin') &&
                              <Tooltip label="Supprimer ce fichier" p={3} hasArrow placement='top'>
                                <Box onClick={(event) => {
                                  event.stopPropagation();
                                  delete_file(attachment.id);
                                  }} className='hvr-grow' cursor={"pointer"} position={"absolute"} right={"-5px"} top={"-8px"}>
                                  <FontAwesomeIcon icon={faTrash} />
                                </Box>
                              </Tooltip>
                            }
                          </Box>
                        ))}
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Box>

              {(user?.matricule == dossier?.author || user?.rank == 'admin') &&
              <Flex>
                <Spacer />
                <Box mt={3} opacity={filesUploading ? 0.5 : 1}>
                    <FormLabel cursor={'pointer'} mt={3} border={"1pt solid #e2e2dd"} ps={3} pe={3} pt={2} pb={2} htmlFor="upload-file"><FontAwesomeIcon style={{marginRight:'5px'}} icon={faPaperclip} />{filesUploading ? "Téléversement..." : "Ajouter une pièce jointe"}</FormLabel>
                    <input multiple={true} accept={ALLOWED_FORMATS.join(',')} id='upload-file' type="file" name="photo" style={{opacity:0, position:"absolute", zIndex:-10}}
                    onChange={handleFileChange}
                    disabled={filesUploading}
                    />
                    <Text cursor={'default'} me={3} align={"right"} fontSize={"sm"} color={"#4a5568"} mt={1}>Max. 15 Mo, Max. 5 fichiers à la fois</Text>
                  </Box>
              </Flex>
              }

              <Text cursor={'default'} mt={5} mb={3} ms={3} color={"#314e89"} fontWeight={'800'} fontSize={'sm'}>ESPACE DE DISCUSSION</Text>
              <Box mb={5} height={'4px'} bgColor={"#314e89"} width={'100%'} />
              <Box p={4} pt={0} mt={0}>  
                <Text cursor={'default'} mt={2} mb={4} color={'#4a4a4a'} fontWeight={'400'} fontSize={'md'}>
                    Échangez au sujet de ce dossier dans l'espace de discussion ci-dessous.
                </Text>
  
                <Threads fromDossier={true} show_category={false} dossier={dossier} />
  
              </Box>
  
              {!pollsLoading && polls.length > 0 && (<>
              
                <Text cursor={'default'} mb={3} ms={3} color={"#314e89"} fontWeight={'800'} fontSize={'sm'}>SONDAGES LIÉS À CE DOSSIER</Text>
                <Box mb={"25px"} height={'4px'} bgColor={"#314e89"} width={'100%'} />
                <Box p={2} pt={0} mt={0}>
    
                  {polls.map((poll, index) => (
                    <Box key={index} mb={index == polls.length-1 ? 0 : 5}>
                      <Poll {...poll} />
                    </Box>
                  ))}
                </Box>
              </>)}

              {pollsLoading && (<>
                <Text cursor={'default'} mb={3} ms={3} color={"#314e89"} fontWeight={'800'} fontSize={'sm'}>SONDAGES LIÉS À CE DOSSIER</Text>
                <Box mb={"25px"} height={'4px'} bgColor={"#314e89"} width={'100%'} />
                <Box p={2} pt={0} mt={0}>
    
                  {[1, 2].map((_, index) => (
                    <Box key={index} mb={index == polls.length-1 ? 0 : 5}>
                      <Skeleton height={"300px"} isLoaded={!pollsLoading} />
                    </Box>
                  ))}
                </Box>
              </>)}

              {/* NEW POLL */}
              {NewPoll(newpoll_disclosure.isOpen, newpoll_disclosure.onOpen, close_newpoll_handler, dossier?._id)}
  
              {!deadlinePassed && (user?.matricule == dossier?.author || user?.rank == 'admin') && !newpoll_disclosure.isOpen && (
                <Flex me={4} mb={4}>
                  <Spacer />
                  <Tooltip label="Ce bouton est visible par vous uniquement" p={3} hasArrow placement='top'>
                    <Button variant={'outline'} colorScheme={'green'} mt={4} rightIcon={<FontAwesomeIcon icon={faSquarePollHorizontal} />} onClick={newpoll_disclosure.onToggle} rounded={0} borderWidth={'1pt'} >Créer un nouveau sondage</Button>
                  </Tooltip>
                </Flex>
              )}
              
              {user?.rank == "admin" && ((deadlinePassed)) &&
                <Box>
                  <Text cursor={'default'} mb={3} ms={3} color={"#5b257d"} fontWeight={'800'} fontSize={'sm'}>ACTIONS</Text>
                  <Box mb={5} height={'4px'} bgColor={"#5b257d"} width={'100%'} />

                  <Button 
                      p={6} m={3} me={0} mt={0} color={"white"} _hover={{backgroundColor:"#d92a2e"}} bgColor={"#b32326"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={delete_disclosure.onOpen} >
                      SUPPRIMER
                  </Button>

                  <Button 
                        p={6} m={3} mt={0} color={"white"} _hover={{backgroundColor:"#F09865"}} bgColor={"#F07F3D"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} 
                        onClick={deadline_disclosure.onOpen} >
                        MODIFIER LA DEADLINE
                  </Button>
                  {DeleteDossierModal(delete_disclosure.isOpen, delete_disclosure.onOpen, delete_disclosure.onClose, delete_dossier)}
                  <ChangeDeadlineModal 
                    currentDeadline={dossier?.deadline ? new Date(dossier?.deadline) : null}
                    isOpen={deadline_disclosure.isOpen} 
                    onOpen={deadline_disclosure.onOpen} 
                    onClose={deadline_disclosure.onClose} 
                    callback={update_deadline} />
                </Box>
              }
              {(
                (dossier?.deadline === null) || (!deadlinePassed) )
                && (user?.matricule == dossier?.author || user?.rank == "admin") &&
                <Box>
                  <Text mb={3} ms={3} color={"#5b257d"} fontWeight={'800'} fontSize={'sm'}>ACTIONS</Text>
                  <Box mb={5} height={'4px'} bgColor={"#5b257d"} width={'100%'} />

                  <Flex>
                    <Spacer />
                    {user?.rank == "admin" &&
                      <Button 
                          p={6} m={3} mt={0} color={"white"} _hover={{backgroundColor:"#d92a2e"}} bgColor={"#b32326"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={delete_disclosure.onOpen} >
                          SUPPRIMER
                      </Button>
                    }
                    <Button 
                        p={6} m={3} mt={0} color={"white"} _hover={{backgroundColor:"#F09865"}} bgColor={"#F07F3D"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} 
                        onClick={deadline_disclosure.onOpen} >
                        MODIFIER LA DEADLINE
                    </Button>
                    <Button 
                        p={6} m={3} mt={0} color={"white"} _hover={{backgroundColor:"#d92a2e"}} bgColor={"#b32326"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={close_disclosure.onOpen} >
                        CLÔTURER CE DOSSIER
                    </Button>
                  </Flex>
                  {CloseDossierModal(close_disclosure.isOpen, close_disclosure.onOpen, close_disclosure.onClose, close_dossier)}
                  {DeleteDossierModal(delete_disclosure.isOpen, delete_disclosure.onOpen, delete_disclosure.onClose, delete_dossier)}
                  <ChangeDeadlineModal 
                    currentDeadline={dossier?.deadline ? new Date(dossier?.deadline) : null}
                    isOpen={deadline_disclosure.isOpen} 
                    onOpen={deadline_disclosure.onOpen} 
                    onClose={deadline_disclosure.onClose} 
                    callback={update_deadline} />
                </Box>
              }

            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </ModalContent>
      </Modal>
    </>)
  }